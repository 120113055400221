import { useEthers, useContractFunction } from '@usedapp/core'
import { Contract } from 'ethers'
import { PRESALE_CONTRACT } from '../../consts'
import abi from '../../contracts/presale.json'

const useRedeem = () => {
  const { library, account } = useEthers()
  const contract = library
    ? new Contract(PRESALE_CONTRACT, abi, library.getSigner())
    : null
  const { send: sendRedeem, state } = useContractFunction(contract, 'redeem')

  const redeem = async () => {
    try {
      if (account) {
        await sendRedeem()
      }
    } catch (error) {
      console.error('Error while claiming:', error)
    }
  }

  return { redeem, state }
}

export default useRedeem
