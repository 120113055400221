import { useEthers, useContractFunction } from '@usedapp/core'
import { Contract } from 'ethers'
import { REFUND_CONTRACT } from '../../consts'
import abi from '../../contracts/refund.json'

const useRefund = () => {
  const { library, account } = useEthers()
  const contract = library
    ? new Contract(REFUND_CONTRACT, abi, library.getSigner())
    : null
  const { send: sendRefund, state } = useContractFunction(
    contract,
    'redeemRefund'
  )

  const refund = async () => {
    try {
      if (account) {
        await sendRefund()
      }
    } catch (error) {
      console.error('Error while claiming refund:', error)
    }
  }

  return { refund, state }
}

export default useRefund
