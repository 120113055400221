import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import { BSCTestnet, DAppProvider, BSC, Mainnet } from '@usedapp/core'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.css'
import { getDefaultProvider } from 'ethers'
import Layout from './components/Layout'
import Presale from './pages/Presale'

const config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: getDefaultProvider('https://eth.llamarpc.com'),
    // [BSCTestnet.chainId]: getDefaultProvider(
    //   'https://data-seed-prebsc-1-s2.binance.org:8545'
    // ),
  },
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Presale />,
      },
    ],
  },
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <RouterProvider router={router} />
    </DAppProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
