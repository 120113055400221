import BusdLogo from '../assets/images/eth-logo.svg'
import { useState } from 'react'
import useMinContribution from '../hooks/read/useMinContribution'
import useYourContribution from '../hooks/read/useYourContribution'
import { useEtherBalance, useEthers } from '@usedapp/core'
import useTotalContributed from '../hooks/read/useTotalContributed'
import useYourBonusContribution from '../hooks/read/useYourBonusContribution'
import useBonusActive from '../hooks/read/useBonusActive'
import useContribute from '../hooks/write/useContribute'
import Web3ModalButton from '../components/Shared/Web3ModalButton'
import useTxToast from '../hooks/useTxToast'
import { utils } from 'ethers'
import { toast } from 'react-hot-toast'
import useRedeem from '../hooks/write/useClaim'
import useRefund from '../hooks/write/useRefund'

const Presale = () => {
  const { account } = useEthers()
  // const budBalance = useEtherBalance(account)
  // const isBonusActive = useBonusActive(account)
  // const minContribution = useMinContribution()
  const yourContribution = useYourContribution(account)
  const yourBonusContribution = useYourBonusContribution(account)
  const totalContributions = useTotalContributed()

  // const { contribute, state: contributeState } = useContribute()
  const { redeem, state: redeemState } = useRedeem()
  const { refund, state: refundState } = useRefund()

  // const [amount, setAmount] = useState()

  const handleContribute = async () => {
    await redeem()
  }

  const handleRefund = async () => {
    await refund()
  }

  useTxToast(redeemState, 'Successfully redeemd!')
  useTxToast(refundState, 'Successfully redeemed refund!')

  return (
    <div className='w-full h-full flex flex-col gap-6 justify-center items-center'>
      <div className='shadow-lg w-[95%] md:w-[480px] bg-white rounded-2xl flex flex-col gap-4 p-4'>
        <div className='flex justify-between'>
          <span className='border-b-2 border-primary'>Presale</span>
        </div>
        {/* <div className='flex flex-col gap-2'>
          <div className='flex justify-between text-gray-400'>
            <span>Contribute</span>
            <span>
              Balance:{' '}
              <span
                className='text-black'
                onClick={() => setAmount(budBalance)}
              >
                {budBalance ? utils.formatEther(budBalance) : 0}
              </span>
            </span>
          </div>
          <div className='flex shadow-md rounded-2xl border'>
            <input
              placeholder='0.02'
              value={amount}
              onChange={({ target }) => setAmount(target.value)}
              className='bg-transparent outline-none flex-1 pl-4 py-4 pr-2 w-[95%]'
            />
            <div className='flex items-center gap-2 border-l pl-2 pr-4 my-4'>
              <img src={BusdLogo} alt='USDT' className='h-4' />
              ETH
            </div>
          </div>
        </div> */}
        <div className='flex flex-col gap-1'>
          {/* <div className='flex justify-between items-center'>
            <span className='opacity-50'>Current Bonus:</span>
            <span className='text-primary'>
              {(isBonusActive * 5).toFixed(2)}%
            </span>
          </div>
          <div className='flex justify-between items-center'>
            <span className='opacity-50'>Minimum contribution</span>
            <span>{minContribution} ETH</span> 
          </div>*/}
          <div className='flex justify-between items-center'>
            <span className='opacity-50'>Your contribution</span>
            <span>{yourContribution} ETH</span>
          </div>
          <div className='flex justify-between items-center'>
            <span className='opacity-50'>Your bonus contribution</span>
            <span className='text-primary'>{yourBonusContribution} ETH</span>
          </div>
          <div className='flex justify-between items-center'>
            <span className='opacity-50'>Total raised</span>
            <span>{totalContributions} ETH</span>
          </div>
        </div>
        {account ? (
          <>
            <button
              onClick={handleContribute}
              className='w-full bg-primary shadow-md p-4 rounded-2xl font-medium text-white'
            >
              Claim
            </button>
            <button
              onClick={handleRefund}
              className='w-full bg-primary shadow-md p-4 rounded-2xl font-medium text-white'
            >
              Claim XBURNS
            </button>
          </>
        ) : (
          <Web3ModalButton className='w-full bg-primary shadow-md p-4 rounded-2xl font-medium text-white' />
        )}
      </div>
    </div>
  )
}

export default Presale
