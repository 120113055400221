import { NavLink } from 'react-router-dom'
import Logo from '../../assets/images/logo.png'
import Web3ModalButton from '../Shared/Web3ModalButton'
import { IoMenu } from 'react-icons/io5'
import { useState } from 'react'

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  return (
    <>
      <div
        className={`${
          isMobileMenuOpen ? 'rounded-b-0' : 'rounded-b-2xl drop shadow-lg'
        } z-20 transition-all flex flex-col fixed transform -translate-x-1/2 left-1/2 w-full lg:w-2/3 bg-white px-6 py-4 gap-6`}
      >
        <div className={`h-full max-h-16 flex justify-between items-center `}>
          <img src={Logo} className='h-full max-h-8' />
          <div className='hidden md:flex gap-8 items-center'>
            <NavLink
              to={'/'}
              className={({ isActive }) =>
                isActive
                  ? 'border-b-primary border-b-2'
                  : 'border-b-2 border-transparent'
              }
            >
              Presale
            </NavLink>
            <a
              href='https://nft.burnswap.finance'
              target='_blank'
              className={
                'transition-all duration-150 hover:border-b-primary border-b-2 border-transparent'
              }
            >
              NFT Sale <span className='text-primary text-xs'> +5.00%</span>
            </a>
            <Web3ModalButton />
          </div>
          <div
            className='flex md:hidden items-center'
            onClick={() => setIsMobileMenuOpen((prev) => !prev)}
          >
            <IoMenu className='text-2xl text-primary' />
          </div>
        </div>
      </div>
      <div
        className={`${
          isMobileMenuOpen ? 'top-16 ' : '-top-full '
        } absolute w-full px-6 py-4 bg-white rounded-b-2xl z-10 transition-all duration-150 md:h-0 flex flex-col gap-4 items-center`}
      >
        <NavLink
          to={'/'}
          className={({ isActive }) =>
            isActive
              ? 'border-b-primary border-b-2'
              : 'border-b-2 border-transparent'
          }
        >
          Presale
        </NavLink>
        <a
          href='https://sidekick.finance/campaign/WJ45CO'
          target='_blank'
          className={
            'transition-all duration-150 hover:border-b-primary border-b-2 border-transparent'
          }
        >
          NFT Sale <span className='text-primary text-xs'> +5.00%</span>
        </a>
        <Web3ModalButton />
      </div>
    </>
  )
}

export default Header
