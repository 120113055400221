import { useCall } from '@usedapp/core'
import { Contract } from 'ethers'
import { PRESALE_CONTRACT } from '../../consts'
import abi from '../../contracts/presale.json'
import { formatEther } from 'ethers/lib/utils'

const useYourContribution = (address) => {
  const { value, error } =
    useCall(
      address &&
        PRESALE_CONTRACT && {
          contract: new Contract(PRESALE_CONTRACT, abi),
          method: 'contributions',
          args: [address],
        }
    ) ?? {}
  if (error) {
    console.error(error.message)
    return undefined
  }
  return value?.[0] ? formatEther(value?.[0]) : 0
}

export default useYourContribution
