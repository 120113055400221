import { useEffect, useState } from 'react'
import { useEthers, shortenAddress, useLookupAddress } from '@usedapp/core'
import { IoLogOutOutline } from 'react-icons/io5'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'

const Web3ModalButton = ({ className }) => {
  const { account, activate, deactivate } = useEthers()
  const { ens } = useLookupAddress(account)
  const [activateError, setActivateError] = useState('')
  const { error } = useEthers()
  useEffect(() => {
    if (error) {
      setActivateError(error.message)
    }
  }, [error])

  const activateProvider = async () => {
    const providerOptions = {
      injected: {
        display: {
          name: 'Metamask',
          description: 'Connect with the provider in your Browser',
        },
        package: null,
      },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: 'https://bridge.walletconnect.org',
          infuraId: 'd8df2cb7844e4a54ab0a782f608749dd',
        },
      },
    }

    const web3Modal = new Web3Modal({
      providerOptions,
    })
    try {
      const provider = await web3Modal.connect()
      await activate(provider)
      setActivateError('')
    } catch (error) {
      setActivateError(error.message)
    }
  }

  return (
    <>
      {/* {activateError && <div>{activateError}</div>} */}
      {account ? (
        <button
          onClick={() => deactivate()}
          className={`${className} bg-primary shadow-md rounded-2xl text-white px-6 py-3 font-medium flex gap-2 items-center`}
        >
          {/* Disconnect{' '} */}
          <p className='hidden lg:inline'>{ens ?? shortenAddress(account)}</p>
          <IoLogOutOutline className='text-xl' />
        </button>
      ) : (
        <button
          onClick={activateProvider}
          className={
            className
              ? className
              : ` bg-primary shadow-md rounded-2xl text-white px-6 py-3 font-medium`
          }
        >
          Connect Wallet
        </button>
      )}
    </>
  )
}

export default Web3ModalButton
