import { useEffect } from 'react'
import { toast } from 'react-hot-toast'

const useTxToast = (txState, success) => {
  useEffect(() => {
    if (txState.status === 'Exception') {
      toast(txState.errorMessage.split(':').splice(-1), {
        icon: '❌',
        style: {
          borderRadius: '15px',
          background: '#fff',
          color: 'black',
        },
      })
    } else if (txState.status === 'Success') {
      toast(success, {
        icon: '✔️',
        style: {
          borderRadius: '15px',
          background: '#fff',
          color: 'black',
        },
      })
    }
  }, [txState, success])
}

export default useTxToast
