import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import { Toaster } from 'react-hot-toast'

const Layout = () => {
  return (
    <>
      <Toaster position='bottom-center' />
      <Header />
      <Outlet />
      <Footer />
    </>
  )
}

export default Layout
